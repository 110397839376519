import React from "react"

export default function PickerPreview({ color }) {
  return (
    <div
      className="picker-preview"
      style={{
        backgroundColor: `hsla(${color.h},${color.s * 100}%,${color.l * 100}%,${
          color.a
        })`,
      }}
    >
      <img src="/images/back-painted-color-picker.png" />
      <div className="block"></div>
      {/* <div
        className="picker-overlay"
        style={{
          backgroundColor: `hsla(${color.h},${color.s * 100}%,${color.l *
            100}%,${color.a})`,
        }}
      ></div> */}
    </div>
  )
}

PickerPreview.defaultProps = {
  color: "#fff",
}
