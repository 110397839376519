import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"
import { IndividualSurvey } from "../components/survey"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import ColorPicker from "@components/ColorPicker"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  DownloadProductBrochureButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const BackPaintedGlass = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout
      heroTitle="BACK PAINTED GLASS"
      heroTitlePosition="bottom"
      heroBackgroundImage="/images/back-painted-glass/header-back-painted-glass.jpg"
    >
      <SEO
        title="Back Painted Glass | ArtVue Glass"
        ogDescription="ArtVue Glass offers a wide range of back painted glass options. Find out how you can create architectural art that will illuminate and brighten your space with the help of back painted glass at ArtVue Glass. View our selection today!"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Unlimited Back Painted Glass Color Options</h2>
            <p>
              Back painted glass adds vibrancy and versatility to any interior
              space. Back painted glass is an eco-friendly, durable, hygienic,
              and versatile surface material for wall cladding, backsplashes,
              countertops, accent walls, marker boards, and more.
            </p>
            <p>
              Ideal for high-traffic spaces, back-painted glass is an excellent
              alternative to other surfacing materials such as wallpaper, wood
              laminate, wall paneling, and paint. Back painted glass is easy to
              maintain, easy to clean, humidity-resistant, and does not require
              grouting.
            </p>
            <p>
                          <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
            </p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/back-painted-glass/back-painted-glass.jpeg"
              alt="Back Painted Glass"
            />
          </div>
        }
      />
      <PageWrapper color="grey">
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Unlimited Color Options</h2>
              <p>
                One of the most appealing aspects of back-painted glass is the
                unlimited color options available. No longer are designers and
                interior decorators limited by color swatches available. Use your
                inspiration and imagination to create colors in limitless
                possibilities.
              </p>
            </div>
          }
        />
        <ColorPicker />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Trust the Experts</h2>
            <p>
              ArtVue Glass has extensive experience producing high-quality and
              durable back painted products. In addition, ArtVue Glass maintains
              the latest color-matching equipment available to ensure your
              design color matches the finished product.
            </p>
            <p>
              Back painted glass can also be custom cut and fitted to
              accommodate any space. With ArtVue Glass' experience and know-how,
              it is possible to incorporate notches, holes, and cutouts without
              compromising the integrity of the glass or the clarity of the
              color.
            </p>
            <p>
              Architects and designers can now specify a water-based,
              eco-friendly paint with confidence in its long-term stability.
            </p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/back-painted-glass/header-back-painted-glass3.jpg"
              alt="Back Painted Glass"
            />
            <p>
              Not sure if back painted glass will fit your color palette? ArtVue
              Glass can supply sample plaques or fully heat cured samples before
              completing your project as part of their quality assurance
              process.
            </p>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>Features and Benefits of Back Painted Glass</h2>
              <ul>
                <li>
                  <strong>Versatile:</strong> Suitable for interior and exterior applications,
                  available tempered, laminated, or annealed, limitless range of
                  colors, a wide range of glass surface options from smooth to
                  textured to lightly etched.
                </li>
                <li>
                  <strong>Durable:</strong> Ideal for high-traffic spaces. Resistant to humidity,
                  scratches, UV radiation, and moisture. Back painted glass by
                  ArtVue Glass has been tested by an independent laboratory.
                </li>
                <li>
                  <strong>Aesthetically Pleasing:</strong> The paint used by ArtVue Glass is
                  explicitly designed for glass decoration, unlike other paints.
                  ArtVue Glass uses ultra-clear, low-iron glass to produce a more
                  accurate color representation that is crisp and clean.
                </li>
                <li>
                  <strong>Eco-Friendly:</strong> The water-based polyurethane paint has near-zero
                  VOCs and is APE content-free. Complies with the latest standards
                  in building sustainability.
                </li>
                <li>
                  <strong>Low Maintenance:</strong> Easy to clean and hygienically non-porous,
                  which helps cut down on germs and bacteria. It does not require
                  grouting between panels, so installation and maintenance are
                  simplified.
                </li>
                <li><strong>Made in the USA</strong>.</li>
              </ul>
              <p>Looking for more info or have special design requirements?</p>
                          <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
            </div>
          }
          textRight={
            <div>
              <h2>Maintenance And Cleaning</h2>
              <p>
                Upholding functionality and ease of use, the maintenance and
                cleaning of back painted glass is a hassle-free endeavor.
              </p>
              <p>
                Choose a non-acid alkali/ammonia-based cleaner. Use a smooth brush
                or cloth and submerge it in the soapy water solution. Then, clean
                the surface with gentle strokes.
              </p>
              <p>
                Ensure the water does not pool towards the edges of the glass as
                this can harm the paint at the back.
              </p>
            </div>
          }
        />
      </div>
      <PageWrapper color="grey">   
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Most Common Back Painted Glass Colors</h2>
            <h3>White Back Painted Glass</h3>
            <p>
              Combining elegance and class, the white back painted glass by
              ArtVue Glass is a sight to set your space apart. With an
              independently tested paint formulation, the rich ultra-white hues
              are the best for wall-cladding in kitchens, washrooms, and living
              spaces alike.
            </p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/back-painted-glass/white-back-painted-glass-wall.jpeg"
              alt="White Back Painted Glass"
            />
          </div>
        }
      />
        
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/back-painted-glass/black-ice-metallic-back-painted-glass.jpg"
              alt="Black Back Painted Glass"
            />
          </div>
        }
        textRight={
          <div>
            <h3>Black Back Painted Glass</h3>
            <p>
              Sleek, modern, and trendy; black back painted glass adds a touch
              of class to the walls for a contemporary interior. Diffusing light
              and reflections, this black beauty by ArtVue Glass depicts a
              satin-like finish with smooth, durable, and easy maintenance
              material.
            </p>
          </div>
        }
      />
        
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Blue Back Painted Glass</h3>
            <p>
              Break free from the usual hues and let blue back painted glass
              light up your space. Environmentally friendly and long-lasting, the glass features a durable color coating and incredible vibrancy.
              Invoke aquatic vibes and breathe life into your space.
            </p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/back-painted-glass/blue-back-painted-glass-wall.jpeg"
              alt="Blue Back Painted Glass"
            />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>Grey Back Painted Glass</h3>
            <p>
              The search for appealing neutrals ends at grey back painted glass
              by ArtVue Glass. The paint formula is tested and ready for use,
              with a durable life, and the ability to withstand backsplashes.
              Let the lights diffuse into the smooth texture of the grey glass
              and bring modernity to your living and professional spaces.
            </p>
            <h3>Best Construction of Back Painted Glass</h3>
            <p>
              With the cutting-edge glass tech at ArtVue Glass, we mold our
              glass palettes and sizes to suit your needs. Exhibiting an expert
              cut, fit, and finish, our smooth back painted glass can be
              customized in any hue. From ultra-whites to humble greys and
              vibrant blues, the sky is the limit to your imagination in back
              painted glass for your interior needs.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/back-painted-glass/low-iron-ultra-clear-glass-back-painted-glass-substrate.jpg" alt="Back Painted Glass" />
          <p>Low Iron (Left) & Standard Green Glass (right)</p>
          </div>
        }
        textRight={
          <div>
            <h2>Smooth Ultra-clear Back Painted Glass Surface</h2>
            <p>
              When looking for class and a modern feel, smooth is just what you
              need, and it is exactly what you get at ArtVue Glass. Instead of
              the usual greenish float glass, we use the low iron flat glass as
              our glass substrate for a smooth, seamless, crystal-clear finish.
            </p>
            <p>
              Do you have a specific thickness or size in mind for your smooth
              back painted glass surface? We customize!
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Textured and Patterned Back Painted Glass Surface</h2>
            <p>
              Are you searching for something more than just smooth? We have you
              covered with our in-house back painting glass facility. We cut,
              grind, laminate, and color your glass as you desire.
            </p>
            <p>
              Add depth to your back painted glass. Choose from our textured and
              patterned glass collection.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/back-painted-glass/pattern-textured-back-painted-glass.jpg" alt="Textured and Patterned Back Painted Glass Surface" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>Laminated Back Painted Glass</h3>
            <p>
              Do you consider glass wall cladding to be slightly dangerous? With
              laminated back painted glass, you remain worry-free.
            </p>
            <p>
              Adding the element of safety to impressive aesthetics, such glass
              allows the shards to remain intact even in case of an accident and
              breakage.
            </p>
            <p>
              Prevent cuts and injuries. Go the safe way with laminated back
              painted glass by ArtVue Glass.
            </p>
            <h3>Structural Laminated Back Painted Glass</h3>
            <p>
              When aesthetics and functionality combine, the result is
              structural laminated back painted glass.
            </p>
            <p>
              At ArtVue Glass, a certified SentryGlas® Laminator, we understand
              the need for modern, less-framed glass structures in contemporary
              architecture. This is why our glass designs are able to withstand
              high water and humidity, exhibiting unmatched strength and
              eye-capturing decorative designs.
            </p>
            <p>
              Perfect for backsplashes and wall cladding, our structural
              laminated back painted glass can be installed in glass stairways,
              washrooms, skylights, railings, and beyond. Easy to install and
              easier to maintain, achieve a glossy finish and artistic interiors
              with our unique back painted glass.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Metallic Back Painted Glass</h3>
            <p>
              No rust, no flake, no dents – a seamless, smooth surface awaits
              your eyes with our metallic back painted glass.
            </p>
            <p>
              A bestselling feature of our collection, the metallic back painted
              glass resembles the look of polished metal – only better! Glossy,
              classic, and smooth to the touch, add a hint of style and class to
              your interiors with the world of metallic hues.
            </p>
            <p>
              Silver, gold, copper, metallic blues, or beyond; the glass allows
              light to continue shining in your space, illuminating your
              interiors and style! (Include thumbnails and images)
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/back-painted-glass/back-painted-glass-metallic-colors.jpg" alt="Metallic Back Painted Glass" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Back Painted Glass Uses and Applications</h2>
            <p>
              Are you tired of staring at large, plain walls? Do you wish to
              glam up your interiors?
            </p>
            <p>
              In residential and commercial spaces alike, back painted glass is
              the new, trendy way to amplify aesthetics and breathe modernity
              into standard interiors.
            </p>
            <p>Here’s how you can use back painted glass:</p>
            <ul>
              <li>Accent Wall</li>
              <li>Wall Cladding</li>
              <li>Backsplashes</li>
              <li>Kitchen Cabinet Facing</li>
              <li>Marker Boards</li>
              <li>Whiteboards</li>
              <li>Shower Walls</li>
              <li>Small Spaces</li>
              <li>Tabletops</li>
              <li>Glass Walls</li>
            </ul>
          </div>
        }
        textRight={
          <div>
            <img src="/images/back-painted-glass/header-back-painted-glass2.jpg" alt="Back Painted Glass" />
            <p>
              From restaurants and bars to casinos, hotels, and homes, back
              painted glass exhibits the art of montage in appealing, accented
              themes to uplift the overall ambiance. Fill the empty spaces with
              colors that matter.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Accent Wall and Wall Cladding with Back Painted Glass</h3>
            <p>
              Sometimes, painting all walls with vibrant hues might seem like
              going overboard. With the distinct wall cladding by ArtVue Glass,
              add an accent wall in the room and make your space stand out.
            </p>
            <p>
              An accent wall is a singular wall in a room that is very different
              from the other walls. With back painted glass, you can ensure the
              wall is not just different, it is extraordinary.
            </p>
            <p>
              From metallics to mattes, choose a hue and texture to adorn your
              accent wall with, and let the back painted glass do wonders for
              your room by bringing in variation and modernity.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/back-painted-glass/back-painted-glass-accent-wall.jpg" alt="Accent Wall and Wall Cladding" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/back-painted-glass/kitchen-backsplash-backpainted-glass.jpg" alt="Backsplash with Back Painted Glass" />
          </div>
        }
        textRight={
          <div>
            <h3>Backsplash with Back Painted Glass</h3>
            <p>
              When looking to improve your kitchen’s aesthetics, the backsplash
              should be your first stop.
            </p>
            <p>
              Located between the cabinet and countertops, the originally tiled
              backsplashes are now being modernized with back painted glass to
              make your kitchen – a canvas of art.
            </p>
            <p>
              With a glossy finish, smooth exterior, and low-maintenance
              surface, add color and life to your food space with spill-free,
              beautiful back painted glass by ArtVue Glass.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Kitchen Cabinet Facing with Back Painted Glass</h3>
            <p>
              Are you tired of the usual, traditional kitchen cabinets? Do you
              wish to give them a much-needed makeover?
            </p>
            <p>
              At ArtVue Glass, we provide back painted glass of your selected
              colors in kitchen cabinet faces to add pops of color to
              contemporary, monotone kitchens. Featuring a smooth surface, the
              glass is easy to wipe, eliminates spill stains, and remains
              germ-free.
            </p>
            <p>
              Begin with one or two cabinet faces or go all out with a thorough
              back painted glass outlook for your kitchen; we strive to satisfy
              you.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/back-painted-glass/kitchen-cabinet-facing-back-painted-glass.jpg" alt="Kitchen Cabinet Facing with Back Painted Glass" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Back Painted Glass in Small Spaces</h3>
            <p>
              If your space is small, there is a way to enhance its look and
              make it appear spacious: Back painted glass.
            </p>
            <p>
              Enlarging compact spaces, back painted glass bounces off the light
              to create a visual effect of spaciousness. Use it in your
              washroom, kitchen, room, or retail store and have your area appear
              bigger, brighter, and bolder than ever before!
            </p>
          </div>
        }
        textRight={
          <div>
            <h3>Back Painted Glass Wall</h3>
            <p>
              Ditch the regular paints and tiles and bring your space alive with
              a vibrant back painted glass wall. From pastels and metallics to
              mattes, ArtVue Glass has an entire palette at your disposal to
              design your wall as you wish.
            </p>
            <p>
              We match our glass cuts and finish with your architectural design
              to uplift the ambiance with matching or contrasting-hued back
              painted glass walls that are as durable as they are aesthetically
              pleasing.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Back Painted Markerboards and Whiteboards</h3>
            <p>
              Modern times require cutting-edge changes, and back painted
              markerboards are here to pave the way.
            </p>
            <p>
              Easy to install and easier to use, back painted glass acts as a
              message board and can be installed in classes, collaboration
              rooms, conference areas, reception desks, and beyond.
            </p>
            <p>
              Use a dry-erase marker to write on the glass and erase it just as
              easily. Unlike metal painted surfaces and melamine resin boards
              that can gain stains and scratches over time, our back painted
              whiteboards are durable and aesthetic, appearing just as new.
            </p>
            <p>
              Combine your boards with steel backs to make them magnetic or
              simply use them as markerboards – your wish is our command.
            </p>
            <p>
              Note: Only use dry-erase markers on back painted glass boards.
              Refrain from using permanent markers.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/back-painted-glass/markerboard-whiteboard-glass-back-painted.jpg" alt="Back Painted Markerboards and Whiteboards" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>Back Painted Glass Countertops</h3>
            <p>
              Are you annoyed with frequent spills and resulting high
              maintenance of traditional countertops?
            </p>
            <p>
              Back painted glass countertops come with functionality,
              durability, and ease of maintenance – all in one. Customize the
              color according to your interiors and add this modern feature to
              your space for an exquisite outlook.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Back Painted Glass Shower Walls</h3>
            <p>
              The humidity and water splashes in your shower can result in
              mildew growth on the walls, which can prove difficult to clean.
              Open possibilities for your shower's ambiance with back painted
              glass shower walls. Water, scrub, and wipe them clean, and they
              are as good as new.
            </p>
            <p>
              With the ability to withstand humidity, steam, and water, adorn
              your shower wall with back painted glass to create a tranquil,
              aesthetic ambiance. Use pastel hues for a spa-like feel or go for
              eccentric bolds to add enthusiasm and joy to your bathroom walls.
            </p>
            <p>
              The possibilities are endless, and the benefits – unmatchable!
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/back-painted-glass/bath-glass-back-painted-shower-wall.jpg" alt="Back Painted Glass Shower Walls" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/back-painted-glass/tabletop-dining-table-glass-back-painted-black.jpg" alt="Back Painted Tabletop" />
          </div>
        }
        textRight={
          <div>
          <h3>Back Painted Tabletop</h3>
            <p>
              Bid farewell to the usual wooden tables and introduce them to the
              art of colored glass.
            </p>
            <p>
              At ArtVue Glass, we revamp old tables to give them a fresh,
              updated look with easy clean back painted tabletops. Choose a
              color, size, and thickness, and let our team work the magic.
            </p>
            <p>
              With back painted glass, we bring something new to the table – pun
              intended.
            </p>
            <h4>Contact Us Today for A Free Consultation!</h4>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
          </div>
        }
      />
      </PageWrapper>
    </PageLayout>
  )
}

export default BackPaintedGlass
