import React, { useState } from "react"
import { ChromePicker } from "react-color"

import PickerPreview from "./PickerPreview"

import "./index.scss"

export default function ColorPicker() {
  const [background, setBackground] = useState({
    h: 250,
    s: 0,
    l: 0.2,
    a: 1,
  })

  const handleChangeComplete = data => {
    if (data.hsl !== background) {
      setBackground(data.hsl)
    }
  }

  return (
    <div className="color-picker-wrapper">
      <div className="picker">
        <ChromePicker color={background} onChange={handleChangeComplete} />
      </div>
      <PickerPreview color={background} />
    </div>
  )
}
